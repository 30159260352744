@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.element-is-visible.slide-in-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.element-is-visible.slide-in-right {
  animation: slideInFromRight 1s ease-out forwards;
}

/* Smooth transitions for all elements */
.observe {
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.element-is-visible {
  opacity: 1;
}

/* Additional styling for review cards */
.review-card {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.review-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Ensure the review text is readable */
.review-text {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
}

/* Google logo styling */
.google-logo {
  transition: transform 0.3s ease-in-out;
}

.google-logo:hover {
  transform: scale(1.1);
}