#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 16px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}
.swiper-button-next,.swiper-button-prev{
  color: red;
  height: 5px;

}
.swiper-custom-nav {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.swiper-custom-nav:hover {
  background-color: rgba(255, 255, 255, 1);
}

.swiper-button-prev:after, .swiper-button-next:after {
  color: #EF4444;
  font-size: 24px;
}
/* Add this to your styles.css or equivalent */
.swiper-custom-nav {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
  color: #000; /* Change color as needed */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Add background */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}


/* Adjust for small screens */
@media (max-width: 640px) { /* Tailwind's 'sm' breakpoint */
  .swiper-custom-nav {
    top: 25%; /* Override for small screens */
  }
}

.swiper-custom-nav:hover {
  background-color: rgba(255, 255, 255, 1);
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

.swiper-custom-nav:hover {
  background-color: rgba(255, 255, 255, 1);
}

.swiper-button-prev {
  left: 10px;
}

.swiper-button-next {
  right: 10px;
}

